import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Switch,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Spinner
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentUser, setCurrentUser] = useState(null);
  const toast = useToast();
  const API_URL = process.env.REACT_APP_API_URL;

  // Filter state: for user group and status
  const [groupFilter, setGroupFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  // Toast helper functions
  const showSuccessToast = (message) => {
    toast({
      title: message,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const showErrorToast = (message) => {
    toast({
      title: message,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  // Determine badge color based on role
  const getBadgeColor = (group) => {
    switch (group) {
      case "Clients":
        return "green";
      case "Providers":
        return "blue";
      case "ProviderRegistration":
        return "orange";
      case "ProviderApplicant":
        return "red";
      case "Admins":
        return "purple";
      default:
        return "gray";
    }
  };

  // Map role values to display labels
  const getRoleDisplayLabel = (role) => {
    switch (role) {
      case "Providers":
        return "Mentors";
      case "ProviderRegistration":
        return "Mentor Registration";
      case "ProviderApplicant":
        return "Mentor Applicants";
      default:
        return role;
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const authentication = await getIdToken();
      const response = await axios.get(`${API_URL}/users/all`, {
        headers: {
          Authorization: `Bearer ${authentication}`,
        },
      });
      // Use the 'body' property from the response
      setUsers(response.data.body);
    } catch (error) {
      showErrorToast("Failed to fetch users");
    }
    setIsLoading(false);
  };

  const toggleUserStatus = async (user) => {
    if (user.enabled) {
      // Note: Check the attribute name returned by your backend
      await disableUser(user.Username);
    } else {
      await enableUser(user.Username);
    }
  };

  const disableUser = async (username) => {
    try {
      const authentication = await getIdToken();
      await axios.post(
        `${API_URL}/users/${username}/disable`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        }
      );
      showSuccessToast(`Successfully disabled user`);
      fetchUsers();
    } catch (error) {
      showErrorToast(`Failed to disable user ${username}`);
    }
  };

  const enableUser = async (username) => {
    try {
      const authentication = await getIdToken();
      await axios.post(
        `${API_URL}/users/${username}/enable`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        }
      );
      showSuccessToast(`Successfully enabled user`);
      fetchUsers();
    } catch (error) {
      showErrorToast(`Failed to enable user ${username}`);
    }
  };

  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  // Toggle functions for filters
  const toggleGroupFilter = (group) => {
    if (groupFilter.includes(group)) {
      setGroupFilter(groupFilter.filter((g) => g !== group));
    } else {
      setGroupFilter([...groupFilter, group]);
    }
  };

  const toggleStatusFilter = (status) => {
    if (statusFilter.includes(status)) {
      setStatusFilter(statusFilter.filter((s) => s !== status));
    } else {
      setStatusFilter([...statusFilter, status]);
    }
  };

  const clearFilters = () => {
    setGroupFilter([]);
    setStatusFilter([]);
  };

  // Apply filtering: if a single status is selected, filter accordingly.
  // For group, if any filters are selected, only show matching users.
  const filteredUsers = users.filter((user) => {
    if (statusFilter.length === 1) {
      if (statusFilter[0] === "enabled" && !user.enabled) return false;
      if (statusFilter[0] === "disabled" && user.enabled) return false;
    }
    if (groupFilter.length > 0 && !groupFilter.includes(user.user_role)) {
      return false;
    }
    return true;
  });

  // Function to download CSV of filtered users
  const downloadCSV = () => {
    const csvRows = [];
    // Define headers
    const headers = ["Username", "Email", "Group", "Status"];
    csvRows.push(headers.join(","));

    // Loop through each filtered user and create a row of CSV data
    filteredUsers.forEach((user) => {
      const row = [
        `"${user.Username}"`,
        `"${user.email}"`,
        `"${user.user_role}"`,
        `"${user.enabled ? "Enabled" : "Disabled"}"`,
      ];
      csvRows.push(row.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "users.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          User Manager
        </Heading>
      </Flex>
      {/* Filter Tags Row */}
      <Box mb={4}>
        <HStack spacing={4}>
          <Text color="white" fontWeight="bold">
            Filters:
          </Text>
          <Tag
            size="md"
            variant={statusFilter.includes("enabled") ? "solid" : "outline"}
            onClick={() => toggleStatusFilter("enabled")}
            cursor="pointer"
          >
            Enabled
          </Tag>
          <Tag
            size="md"
            variant={statusFilter.includes("disabled") ? "solid" : "outline"}
            onClick={() => toggleStatusFilter("disabled")}
            cursor="pointer"
          >
            Disabled
          </Tag>
          <Tag
            size="md"
            variant={groupFilter.includes("Clients") ? "solid" : "outline"}
            onClick={() => toggleGroupFilter("Clients")}
            cursor="pointer"
          >
            Clients
          </Tag>
          <Tag
            size="md"
            variant={groupFilter.includes("Providers") ? "solid" : "outline"}
            onClick={() => toggleGroupFilter("Providers")}
            cursor="pointer"
          >
            Mentors
          </Tag>
          <Tag
            size="md"
            variant={groupFilter.includes("ProviderRegistration") ? "solid" : "outline"}
            onClick={() => toggleGroupFilter("ProviderRegistration")}
            cursor="pointer"
          >
            Mentor Registration
          </Tag>
          <Tag
            size="md"
            variant={groupFilter.includes("ProviderApplicant") ? "solid" : "outline"}
            onClick={() => toggleGroupFilter("ProviderApplicant")}
            cursor="pointer"
          >
            Mentor Applicants
          </Tag>
          <Tag
            size="md"
            variant={groupFilter.includes("Admins") ? "solid" : "outline"}
            onClick={() => toggleGroupFilter("Admins")}
            cursor="pointer"
          >
            Admins
          </Tag>
          <Button size="sm" onClick={clearFilters}>
            Clear Filters
          </Button>
          <Button size="sm" onClick={downloadCSV}>
            Download CSV
          </Button>
        </HStack>
      </Box>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" minH="50vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>Email</Th>
                <Th>Group</Th>
                <Th>Status</Th>
                {/* <Th>Actions</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {filteredUsers.map((user) => {
                return (
                  <Tr key={user.Username}>
                    <Td>
                      <HStack justify="flex-start">
                        <Avatar
                          size="xs"
                          name={`${user.given_name} ${user.family_name}`}
                          src={user.profile_picture_url}
                        />
                        <Text color={"white"} fontSize="xs" fontWeight="bold">
                          {`${user.given_name} ${user.family_name}`}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>{user.email}</Td>
                    <Td>
                      <Tag
                        colorScheme={getBadgeColor(user.user_role)}
                        size="sm"
                        variant="outline"
                        rounded={"full"}
                      >
                        {getRoleDisplayLabel(user.user_role)}
                      </Tag>
                    </Td>
                    <Td>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel
                          htmlFor={`status-switch-${user.Username}`}
                          mb="0"
                        >
                          {user.enabled ? "Enabled" : "Disabled"}{" "}
                        </FormLabel>
                        <Switch
                          id={`status-switch-${user.Username}`}
                          isChecked={user.enabled}
                          onChange={() => toggleUserStatus(user)}
                        />
                      </FormControl>
                    </Td>
                    {/* Uncomment below if you wish to add further actions */}
                    {/* <Td>
                      <Button onClick={() => openUserModal(user)}>Edit</Button>
                    </Td> */}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default UserManager;
