import { CheckIcon, DeleteIcon, ViewIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
  Skeleton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
  TableContainer,
  FormLabel,
  Divider,
  SimpleGrid,
  SkeletonCircle,
  SkeletonText,
  Image,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BsCashCoin,
  BsPersonBadge,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { Form, useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import { IoRefresh } from "react-icons/io5";

const API_URL = process.env.REACT_APP_API_URL;

const InfoCard = ({ title, children }) => (
  <Box bg="oleniumBlack.700" p={4} borderRadius="lg">
    <Heading size="md" mb={4}>{title}</Heading>
    <VStack align="stretch" spacing={3}>
      {children}
    </VStack>
  </Box>
);

const InfoItem = ({ label, value }) => (
  <Flex>
    <Text fontWeight="bold" width="40%" color="gray.400">{label}:</Text>
    <Text flex={1}>{value}</Text>
  </Flex>
);

const ProjectsTable = () => {
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [action, setAction] = useState("");
  const [enterpriseAccounts, setEnterpriseAccounts] = useState([]);
  const [timerIds, setTimerIds] = useState({});
  const [providerInfo, setProviderInfo] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isChatAnalysisModalOpen, setChatAnalysisModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Filter state variables
  const [assignmentFilter, setAssignmentFilter] = useState([]);
  const [enterpriseFilter, setEnterpriseFilter] = useState([]);
  const [hoursFilter, setHoursFilter] = useState(false);

  useEffect(() => {
    projects.forEach(async (project) => {
      if (project.provider_id == "N/A") {
        return;
      }
      const profile = await getProviderNameAndProfilePicture(project.provider_id);
      setProviderInfo((prevState) => ({
        ...prevState,
        [project.id]: profile,
      }));
    });
  }, [projects]);

  const renderStatus = (status) => {
    const statusWords = status.split("_").map((word) => {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
    return statusWords.join(" ");
  };

  const convertTime = (time) => {
    const deadline = new Date(time);
    const now = new Date();
    const days = Math.floor((deadline - now) / (1000 * 60 * 60 * 24));
    const hours = Math.floor(((deadline - now) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (days === 0 && hours === 0) {
      return "Less than an hour";
    }
    if (hours === 1) {
      return `${days} days and ${hours} hour`;
    }
    return `${days} days and ${hours} hours`;
  };

  useEffect(() => {
    Object.values(timerIds).forEach(clearInterval);
    const newTimerIds = {};
    projects.forEach((project) => {
      newTimerIds[project.id] = setInterval(() => {
        setProjects((prevProjects) => [...prevProjects]);
      }, 60000);
    });
    setTimerIds(newTimerIds);
    return () => {
      Object.values(timerIds).forEach(clearInterval);
    };
  }, [projects]);

  const fetchData = async (entity, setState) => {
    setLoading(true);
    try {
      const authentication = await getIdToken();
      const response = await fetch(`${API_URL}/${entity}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      const data = await response.json();
      if (data.statusCode === 200) {
        const parsedData = JSON.parse(data.body);
        if (Array.isArray(parsedData)) {
          setState(parsedData);
        }
      } else {
        console.error(`Error status code: ${data.statusCode}`);
        setState([]);
      }
    } catch (error) {
      console.error(`Error fetching ${entity}:`, error);
      setState([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchEnterpriseAccounts = async () => {
    setLoading(true);
    try {
      const authentication = await getIdToken();
      const response = await axios.get(`${API_URL}/enterprise`, {
        headers: {
          Authorization: `Bearer ${authentication}`,
        },
      });
      
      if (response.status === 200 && response.data) {
        setEnterpriseAccounts(response.data);
      } else {
        console.error("Unexpected response:", response);
        setEnterpriseAccounts([]);
      }
    } catch (error) {
      console.error("Error fetching corporate accounts:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
      setEnterpriseAccounts([]);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (project, modalAction) => {
    setAction(modalAction);
    setCurrentProject(project);
    if (modalAction === "AssignEnterprise") {
      fetchEnterpriseAccounts();
    }
    onOpen();
  };

  const openChatAnalysisModal = (projectId) => () => {
    setChatAnalysisModalOpen(true);
  };

  const returnNumberOfProjects = (status) => {
    return projects.filter((project) => project.status === status).length;
  };

  const getProviderNameAndProfilePicture = async (id) => {
    const authentication = await getIdToken();
    const userResponse = await axios.get(`${API_URL}/users/info/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authentication}`,
      },
    });

    const profile = userResponse.data.body;

    return profile;
  };

  const handleRefresh = () => {
    fetchData("projects", setProjects);
    fetchData("users", setUsers);
    fetchEnterpriseAccounts();
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  // Toggle functions for filters
  const toggleAssignmentFilter = (filterValue) => {
    if (assignmentFilter.includes(filterValue)) {
      setAssignmentFilter(assignmentFilter.filter((f) => f !== filterValue));
    } else {
      setAssignmentFilter([...assignmentFilter, filterValue]);
    }
  };

  const toggleEnterpriseFilter = (filterValue) => {
    if (enterpriseFilter.includes(filterValue)) {
      setEnterpriseFilter(enterpriseFilter.filter((f) => f !== filterValue));
    } else {
      setEnterpriseFilter([...enterpriseFilter, filterValue]);
    }
  };

  const clearFilters = () => {
    setAssignmentFilter([]);
    setEnterpriseFilter([]);
    setHoursFilter(false);
  };

  // Apply filtering based on the selected tags
  const filteredProjects = projects.filter((project) => {
    // Assignment filter: if only one of the two options is selected, apply that filter.
    if (assignmentFilter.length === 1) {
      if (assignmentFilter[0] === "assigned" && project.provider_id === "N/A") {
        return false;
      }
      if (assignmentFilter[0] === "unassigned" && project.provider_id !== "N/A") {
        return false;
      }
    }
    // Enterprise filter: similarly, if only one is selected then filter.
    if (enterpriseFilter.length === 1) {
      if (enterpriseFilter[0] === "enterprise" && project.enterprise_id === "N/A") {
        return false;
      }
      if (enterpriseFilter[0] === "nonEnterprise" && project.enterprise_id !== "N/A") {
        return false;
      }
    }
    // Hours filter: only show projects with meeting_hours > 0 if selected.
    if (hoursFilter && project.meeting_hours <= 0) {
      return false;
    }
    return true;
  });

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          Project Manager
        </Heading>
        <Spacer />
        <IconButton
          aria-label="Refresh"
          icon={<IoRefresh />}
          onClick={handleRefresh}
          isLoading={loading}
        />
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md" bg={"oleniumBlack.600"}>
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Total projects
            </Text>
            <Skeleton isLoaded={!loading}>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {projects.length}
              </Text>
            </Skeleton>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md" bg={"oleniumBlack.600"}>
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Awaiting assignment
            </Text>
            <Skeleton isLoaded={!loading}>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {returnNumberOfProjects("AWAITING_ASSIGNMENT")}
              </Text>
            </Skeleton>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md" bg={"oleniumBlack.600"}>
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              In progress
            </Text>
            <Skeleton isLoaded={!loading}>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {returnNumberOfProjects("IN_PROGRESS")}
              </Text>
            </Skeleton>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md" bg={"oleniumBlack.600"}>
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Completed
            </Text>
            <Skeleton isLoaded={!loading}>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {returnNumberOfProjects("COMPLETED")}
              </Text>
            </Skeleton>
          </Box>
        </GridItem>
      </Grid>
      {/* Filter Tags Row */}
      <Box mb={4}>
        <HStack spacing={4}>
          <Text color="white" fontWeight="bold">Filters:</Text>
          <Tag
            size="md"
            variant={assignmentFilter.includes("assigned") ? "solid" : "outline"}
            onClick={() => toggleAssignmentFilter("assigned")}
            cursor="pointer"
          >
            Assigned
          </Tag>
          <Tag
            size="md"
            variant={assignmentFilter.includes("unassigned") ? "solid" : "outline"}
            onClick={() => toggleAssignmentFilter("unassigned")}
            cursor="pointer"
          >
            Unassigned
          </Tag>
          <Tag
            size="md"
            variant={hoursFilter ? "solid" : "outline"}
            onClick={() => setHoursFilter(!hoursFilter)}
            cursor="pointer"
          >
            Has Hours Remaining
          </Tag>
          <Tag
            size="md"
            variant={enterpriseFilter.includes("enterprise") ? "solid" : "outline"}
            onClick={() => toggleEnterpriseFilter("enterprise")}
            cursor="pointer"
          >
            Enterprise
          </Tag>
          <Tag
            size="md"
            variant={enterpriseFilter.includes("nonEnterprise") ? "solid" : "outline"}
            onClick={() => toggleEnterpriseFilter("nonEnterprise")}
            cursor="pointer"
          >
            Non-Enterprise
          </Tag>
          <Button size="sm" onClick={clearFilters}>
            Clear Filters
          </Button>
        </HStack>
      </Box>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Service Name</Th>
            <Th>Mentor</Th>
            <Th>Client</Th>
            <Th>Active Subscription</Th>
            <Th>Hours Remaining</Th>
            <Th>Enterprise</Th>
            <Th minW={200}>
              Status
            </Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading
            ? Array.from({ length: 5 }).map((_, index) => (
                <Tr key={index}>
                  <Td><Skeleton height="20px" /></Td>
                  <Td><Skeleton height="20px" /></Td>
                  <Td><Skeleton height="20px" /></Td>
                  <Td><Skeleton height="20px" /></Td>
                  <Td><Skeleton height="20px" /></Td>
                  <Td><Skeleton height="20px" /></Td>
                </Tr>
              ))
            : filteredProjects.map((project) => (
                <Tr key={project.id}>
                  <Td>{project.service_name}</Td>
                  <Td>
                    <HStack justify="flex-start">
                      <Avatar
                        size="xs"
                        name={`${providerInfo[project.id]?.given_name || ""} ${providerInfo[project.id]?.family_name || "Unassigned"}`}
                        src={providerInfo[project.id]?.profile_picture_url || ""}
                      />
                      <Text color={"white"} fontSize="xs" fontWeight="bold">
                        {`${providerInfo[project.id]?.given_name || ""} ${providerInfo[project.id]?.family_name || "Unassigned"}`}
                      </Text>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack justify="flex-start">
                      <Avatar
                        size="xs"
                        name={project.user_name}
                        src={users.find((user) => user.username === project.user_id)?.profile_picture_url}
                      />
                      <Text color={"white"} fontSize="xs" fontWeight="bold">
                        {project.user_name}
                      </Text>
                    </HStack>
                  </Td>
                  <Td>
                    {project.active_subscriptions ? (
                      <HStack>
                        <CheckIcon color="green.500" />
                        <Text color={"white"} fontSize="xs" fontWeight="bold">
                          Active
                        </Text>
                      </HStack>
                    ) : (
                      <HStack>
                        <WarningIcon color="red.500" />
                        <Text color={"white"} fontSize="xs" fontWeight="bold">
                          Inactive
                        </Text>
                      </HStack>
                    )}
                  </Td>
                  <Td>
                    <Text color={"white"} fontSize="xs" fontWeight="bold">
                      {project.meeting_hours}
                    </Text>
                  </Td>
                  <Td>
                    <Text color={"white"} fontSize="xs" fontWeight="bold">
                      {enterpriseAccounts.find(acc => acc.account_id === project.enterprise_id)?.name || "N/A"}
                    </Text>
                  </Td>
                  <Td>
                    <Tag
                      size="md"
                      variant="outline"
                      rounded="full"
                      colorScheme={
                        project.status === "AWAITING_ASSIGNMENT"
                          ? "yellow"
                          : project.status === "IN_PROGRESS"
                          ? "blue"
                          : project.status === "COMPLETED"
                          ? "green"
                          : "red"
                      }
                    >
                      {renderStatus(project.status)}
                    </Tag>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton>
                        <IconButton
                          aria-label="Options"
                          icon={<BsThreeDotsVertical />}
                          size="xs"
                          variant="outline"
                          rounded="full"
                          mx={2}
                        />
                      </MenuButton>
                      <MenuList bg={"oleniumBlack.600"}>
                        <MenuItem
                          icon={<ViewIcon />}
                          onClick={() => openModal(project, "View")}
                          bg={"oleniumBlack.600"}
                        >
                          View project
                        </MenuItem>
                        <MenuItem
                          icon={<CheckIcon />}
                          onClick={() => openModal(project, "Complete")}
                          bg={"oleniumBlack.600"}
                        >
                          Mark as completed
                        </MenuItem>
                        <MenuItem
                          icon={<BsPersonBadge />}
                          onClick={() => openModal(project, "Assign")}
                          bg={"oleniumBlack.600"}
                        >
                          Assign provider
                        </MenuItem>
                        <MenuItem
                          icon={<BsCashCoin />}
                          onClick={() => openModal(project, "AssignEnterprise")}
                          bg={"oleniumBlack.600"}
                          isDisabled={project.enterprise_id !== "N/A"}
                        >
                          Assign enterprise
                        </MenuItem>
                        <MenuItem
                          icon={<DeleteIcon color="red.600" />}
                          onClick={() => openModal(project, "Delete")}
                          bg={"oleniumBlack.600"}
                          color={"red.600"}
                        >
                          Delete project
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
        </Tbody>
      </Table>
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        currentProject={currentProject}
        action={action}
        enterpriseAccounts={enterpriseAccounts}
        fetchProjects={handleRefresh}
      />
      <ChatAnalysisModal
        isOpen={isChatAnalysisModalOpen}
        onClose={() => setChatAnalysisModalOpen(false)}
      />
    </Box>
  );
};

const ActionModal = ({ isOpen, onClose, currentProject, action, enterpriseAccounts, fetchProjects }) => {
  const [deleteInput, setDeleteInput] = useState('');
  const [provider, setProvider] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [credits, setCredits] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteChats, setDeleteChats] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [remainingCredits, setRemainingCredits] = useState(0);

  const toast = useToast();

  useEffect(() => {
    if (action === 'Assign') {
      fetchProviders();
    }
  }, [action]);

  useEffect(() => {
    if (enterprise) {
      const account = enterpriseAccounts.find(acc => acc.account_id === enterprise);
      setSelectedAccount(account);
      setRemainingCredits(account.credits_remaining);
    }
  }, [enterprise, enterpriseAccounts]);

  useEffect(() => {
    if (selectedAccount) {
      setRemainingCredits(selectedAccount.credits_remaining - credits);
    }
  }, [credits, selectedAccount]);

  useEffect(() => {
    if (currentProject) {
      if (action === 'Delete') {
        setIsValid(deleteInput === currentProject.service_name);
      } else if (action === 'Assign') {
        setIsValid(provider !== '');
      } else if (action === 'Complete') {
        setIsValid(true);
      } else if (action === 'AssignEnterprise') {
        setIsValid(enterprise !== '' && credits > 0);
      }
    }
  }, [deleteInput, provider, enterprise, credits, action, currentProject?.name]);

  useEffect(() => {
    if (isOpen) {
      if (action === 'Delete') {
        setDeleteInput('');
      }
    }
  }, [isOpen, action]);

  const fetchProviders = async () => {
    setLoading(true);
    const idToken = await getIdToken();
    const apiUrl = process.env.REACT_APP_API_URL;
  
    try {
      const response = await axios.get(`${apiUrl}/project/providers/all`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (Array.isArray(response.data)) {
        setProviders(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        toast({
          title: 'An error occurred.',
          description: 'Received an unexpected response format from the server.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(`Error fetching providers: ${error}`);
      toast({
        title: 'An error occurred.',
        description: 'An unexpected error occurred while fetching providers.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  

  const handleConfirm = async () => {
    setLoading(true);
    const idToken = await getIdToken();

    if (action === 'Delete') {
      try {
        await axios.delete(`${API_URL}/projects/${currentProject.id}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
        });
        toast({
          title: 'Project Deleted.',
          description: 'The project has been successfully deleted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error(`Error deleting project: ${error}`);
        toast({
          title: 'An error occurred.',
          description: 'Could not delete the project.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else if (action === 'Assign' || action === 'Complete' || action === 'AssignEnterprise') {
      let updatedFields = {};

      if (action === 'Assign') {
        updatedFields = {
          provider_id: provider,
          status: 'AWAITING_ACCEPTANCE',
          start_date: new Date().toISOString(),
          delete_chats: deleteChats,
        };
      } else if (action === 'Complete') {
        updatedFields = { status: 'COMPLETED' };
      } else if (action === 'AssignEnterprise') {
        updatedFields = { enterprise_id: enterprise, meeting_hours: credits };
      }

      try {
        await axios.put(
          `${API_URL}/projects/${currentProject.id}`,
          updatedFields,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        toast({
          title: 'Project Updated.',
          description: 'The project has been successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchProjects();
      } catch (error) {
        console.error(`Error updating project: ${error}`);
        toast({
          title: 'An error occurred.',
          description: 'Could not update the project.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
    onClose();
  };

  const renderSubTags = (subTags = []) => {
    return subTags.map((tag, index) => (
      <Tag key={index} size="sm" variant="solid" colorScheme="blue">
        {tag}
      </Tag>
    ));
  };
  

  const renderActiveSubscriptions = (activeSubscriptions) => {
    return activeSubscriptions ? <CheckIcon color="green.500" /> : <WarningIcon color="red.500" />;
  };

  const renderStatus = (status) => {
    const statusWords = status.split('_').map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return statusWords.join(' ');
  }
  

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={action === 'View' ? '4xl' : 'md'}>
      <ModalOverlay />
      <ModalContent bg={'oleniumBlack.600'}>
        <ModalHeader>{action} Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

        {action === "View" && currentProject && (
          <Box>
            <Flex mb={6} bg="oleniumBlack.700" px={6} borderRadius="xl" alignItems="center">
              <VStack align="start" spacing={2} flex={1} p={6}>
                <Heading size="lg">{currentProject.service_name}</Heading>
                <HStack>{renderSubTags(currentProject.sub_tags)}</HStack>
                <Text fontSize="sm" color="gray.400">{currentProject.problem_statement}</Text>
              </VStack>
            </Flex>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              <InfoCard title="Project Details">
                <InfoItem label="Status" value={renderStatus(currentProject.status)} />
                <InfoItem label="Provider" value={currentProject.provider_name === "N/A" ? "Not Assigned" : currentProject.provider_name} />
                <InfoItem label="Client" value={currentProject.user_name} />
                <InfoItem 
                  label="Subscription" 
                  value={
                    <Flex align="center">
                      {renderActiveSubscriptions(currentProject.active_subscriptions)}
                      <Text ml={2}>{currentProject.active_subscriptions ? "Active" : "Inactive"}</Text>
                    </Flex>
                  } 
                />
                <InfoItem label="Created At" value={new Date(currentProject.created_at).toLocaleString()} />
                <InfoItem label="Renewal Date" value={currentProject.renewal_date === "N/A" ? "Not Applicable" : currentProject.renewal_date} />
              </InfoCard>

              <InfoCard title="Meeting Preferences">
                <InfoItem label="Preferred Days" value={currentProject.preferred_day.join(", ")} />
                <InfoItem label="Preferred Times" value={currentProject.preferred_time.join(", ")} />
                <InfoItem label="Meeting Hours" value={currentProject.meeting_hours} />
              </InfoCard>

            </SimpleGrid>


            <Box
              bg="oleniumBlack.700"
              p={4} 
              borderRadius="lg"
              mt={6}
            >
              <Text color="white" fontSize="lg" fontWeight="bold">Project Requirements</Text>

              <Text color="white" fontSize="sm" mt={4} fontWeight="bold">Reason for joining</Text>
              <Text color="white" fontSize="sm">{currentProject.reason_for_joining}</Text>

            </Box>

          </Box>
        )}




          {action === 'Delete' && (
            <div>
              <Text mb={4}>
                Are you sure you want to delete this project? This action cannot
                be undone.
              </Text>
              <Input
                value={deleteInput}
                onChange={(e) => setDeleteInput(e.target.value)}
                placeholder='Project name'
              />
            </div>
          )}
          {action === 'Assign' && (
            <div>
              {providers.length === 0 ? (
                <Text mb={4}>No providers available.</Text>
              ) : (
                <>
                <Text mt={4}>Select a provider to assign this project to.</Text>
                  <HStack mb={4}>
                    <Select onChange={(e) => setProvider(e.target.value)} mt={4}>
                      <option value='' disabled selected>
                        Select Provider
                      </option>
                      {providers.map((provider, index) => (
                        <option key={index} value={provider.username}>
                          {provider.given_name} {provider.family_name}
                        </option>
                      ))}
                    </Select>
                    <IconButton
                      aria-label='Refresh'
                      icon={<IoRefresh />}
                      onClick={fetchProviders}
                      isLoading={loading}
                      mt={4}
                    />
                  </HStack>
                  <Checkbox isChecked={deleteChats} onChange={(e) => setDeleteChats(e.target.checked)}>
                    Delete all chats within this project
                  </Checkbox>
                </>
              )}
            </div>
          )}
          {action === 'Complete' && (
            <div>
              <Text mb={4}>
                Are you sure you want to mark this project as completed? This action cannot be undone and will finalise the project between the provider and the client.
              </Text>
            </div>
          )}
          {action === 'AssignEnterprise' && (
            <div>
              {enterpriseAccounts.length === 0 ? (
                <Text mb={4}>No corporate accounts available.</Text>
              ) : (
                <VStack mb={4} align='start' spacing={0}>
                  <FormLabel>Assign Enterprise Account</FormLabel>
                  <Text fontSize='sm' mb={4}>
                    Assign this project to an enterprise account
                  </Text>
                  <Select onChange={(e) => setEnterprise(e.target.value)}>
                    <option value='' disabled selected>
                      Select Enterprise Account
                    </option>
                    {enterpriseAccounts.map((account) => (
                      <option key={account.account_id} value={account.account_id}>
                        {account.name}
                      </option>
                    ))}
                  </Select>

                  <FormLabel mt={6}>Assign Credits</FormLabel>
                  <Text mb={4} fontSize='sm'>
                    Assign credits to this project from an enterprise account.
                  </Text>
                  <NumberInput
                    value={credits}
                    onChange={(value) => setCredits(Number(value))}
                    min={1}
                    max={selectedAccount ? selectedAccount.credits_remaining : 0}
                  >
                    <NumberInputField placeholder="Credits" size={'xl'} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </VStack>
              )}
              {selectedAccount && (
                <>
                  <Divider mb={4} />
                  <TableContainer>
                    <Table size='sm'>
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Credits Issued</Th>
                          <Th>Credits Remaining</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{selectedAccount.name}</Td>
                          <Td>{selectedAccount.credits_issued}</Td>
                          <Td>{remainingCredits}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter mb={4}>
          <Button onClick={onClose} variant={action === 'View' ? 'ExperienceBankGreen' : 'ghost'}>
            Close
          </Button>
          <Spacer />
          {action !== 'View' && (
            <Button 
              variant='ExperienceBankGreen' 
              onClick={handleConfirm} 
              isDisabled={!isValid || (action === 'AssignEnterprise' && remainingCredits < 0)}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};


const ChatAnalysisModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent bg={"oleniumBlack.600"}>
        <ModalHeader>Chat Analysis</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{/* Your chat analysis content here */}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectsTable;
