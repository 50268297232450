import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  CheckboxGroup,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  StackDivider,
  Tag,
  Text,
  Textarea,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Grid,
  GridItem,
  Wrap,
  WrapItem,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaArrowLeft } from "react-icons/fa";
import { BsPlusLg } from "react-icons/bs";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { AiOutlineEnvironment } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import Countries from "../../../Countries";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Helper function to format a YYYY-MM string to "Month YYYY"
 */
const formatDate = (dateString) => {
  if (!dateString) return "";
  const [year, month] = dateString.split("-");
  const date = new Date(year, month - 1);
  return date.toLocaleString("default", { month: "long", year: "numeric" });
};

/**
 * List of months
 */
const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

/**
 * Generate a list of years (from 1950 to current year)
 */
const generateYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= 1950; year--) {
    years.push(year.toString());
  }
  return years;
};

const years = generateYears();

/**
 * WorkExperienceItem renders a single work experience entry.
 * (No changes made to work experience components.)
 */
const WorkExperienceItem = ({ work, onDelete, onEdit, canDelete }) => {
  const handleDelete = () => {
    onDelete(work.id);
  };

  const handleEdit = () => {
    onEdit(work.id);
  };

  return (
    <Stack
      justify="space-between"
      direction="row"
      spacing="4"
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="sm"
    >
      <Stack spacing="0.5" fontSize="sm">
        <Text fontWeight="bold">
          {work.title} • {work.company} • {formatDate(work.startDate)} -{" "}
          {work.endDate ? formatDate(work.endDate) : "Present"}
        </Text>
        <Text mt={2}>{work.description}</Text>
        <HStack spacing="3" mt="2">
          <Tag size="sm" variant="solid" colorScheme="experienceBankGreen">
            {work.location}
          </Tag>
          <Tag size="sm" variant="solid" colorScheme="experienceBankGreen">
            {work.country}
          </Tag>
        </HStack>
      </Stack>
      <Stack direction={{ base: "column", sm: "row" }} spacing={{ base: "0", sm: "1" }}>
        <IconButton
          icon={<FiEdit2 />}
          onClick={handleEdit}
          variant="ghost"
          aria-label="Edit experience"
        />
        {canDelete && (
          <IconButton
            icon={<FiTrash2 />}
            onClick={handleDelete}
            variant="ghost"
            colorScheme="red"
            aria-label="Delete experience"
          />
        )}
      </Stack>
    </Stack>
  );
};

/**
 * WorkExperienceForm renders the form for adding or editing work experience.
 * (No changes made to work experience components.)
 */
const WorkExperienceForm = ({
  initialValues,
  onSubmit,
  formErrors,
  setFormErrors,
  setFormTouched,
  formTouched
}) => {
  const [formValues, setFormValues] = useState({
    title: initialValues?.title || "",
    company: initialValues?.company || "",
    location: initialValues?.location || "",
    country: initialValues?.country || "",
    startMonth: initialValues?.startDate ? initialValues.startDate.split("-")[1] : "",
    startYear: initialValues?.startDate ? initialValues.startDate.split("-")[0] : "",
    endMonth: initialValues?.endDate ? initialValues.endDate.split("-")[1] : "",
    endYear: initialValues?.endDate ? initialValues.endDate.split("-")[0] : "",
    description: initialValues?.description || "",
    present: initialValues?.endDate ? false : true,
  });

  const validate = () => {
    const errors = {};
    const today = new Date();
    const selectedStartDate =
      formValues.startMonth && formValues.startYear
        ? new Date(formValues.startYear, formValues.startMonth - 1)
        : null;
    const selectedEndDate =
      formValues.present
        ? null
        : formValues.endMonth && formValues.endYear
        ? new Date(formValues.endYear, formValues.endMonth - 1)
        : null;

    if (!formValues.title) errors.title = "Title is required";
    if (formValues.title.length > 50) errors.title = "Title cannot exceed 50 characters";
    if (!formValues.company) errors.company = "Company is required";
    if (formValues.company.length > 50) errors.company = "Company cannot exceed 50 characters";
    if (!formValues.startMonth || !formValues.startYear) {
      errors.startDate = "Start date is required";
    } else if (selectedStartDate > today) {
      errors.startDate = "Start date cannot be in the future";
    }
    if (!formValues.present) {
      if (!formValues.endMonth || !formValues.endYear) {
        errors.endDate = "End date is required";
      } else if (selectedEndDate && selectedStartDate && selectedEndDate < selectedStartDate) {
        errors.endDate = "End date cannot be before start date";
      }
    }
    if (formValues.description.length > 400)
      errors.description = "Description cannot exceed 400 characters";

    setFormErrors(errors);
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormTouched(true);
    validate();
    if (Object.keys(formErrors).length === 0) {
      const formattedWork = {
        title: formValues.title,
        company: formValues.company,
        location: formValues.location,
        country: formValues.country,
        startDate:
          formValues.startMonth && formValues.startYear
            ? `${formValues.startYear}-${formValues.startMonth}`
            : "",
        endDate: formValues.present
          ? null
          : formValues.endMonth && formValues.endYear
          ? `${formValues.endYear}-${formValues.endMonth}`
          : "",
        description: formValues.description,
      };
      onSubmit(formattedWork);
    }
  };

  return (
    <form id="work-experience-form" onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <FormControl isInvalid={formTouched && formErrors.title}>
          <FormLabel>Job Title</FormLabel>
          <Input
            type="text"
            placeholder="Title"
            name="title"
            value={formValues.title}
            onChange={handleChange}
          />
          <FormErrorMessage>{formErrors.title}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formTouched && formErrors.company}>
          <FormLabel>Company</FormLabel>
          <Input
            type="text"
            placeholder="Company"
            name="company"
            value={formValues.company}
            onChange={handleChange}
          />
          <FormErrorMessage>{formErrors.company}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formTouched && formErrors.description}>
          <FormLabel>Description</FormLabel>
          <Textarea
            placeholder="Description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
          />
          <FormErrorMessage>{formErrors.description}</FormErrorMessage>
        </FormControl>
        <HStack width="100%" spacing={4}>
          <FormControl width="50%">
            <FormLabel>Location</FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <Icon as={AiOutlineEnvironment} />
              </InputLeftAddon>
              <Input
                type="text"
                placeholder="Location"
                name="location"
                value={formValues.location}
                onChange={handleChange}
              />
            </InputGroup>
          </FormControl>
          <FormControl width="50%">
            <FormLabel>Country</FormLabel>
            <Select
              placeholder="Select Country"
              name="country"
              value={formValues.country}
              onChange={handleChange}
            >
              {Countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>
        <HStack width="100%" spacing={4}>
          <FormControl isInvalid={formTouched && formErrors.startDate} width="50%">
            <FormLabel>Start Date</FormLabel>
            <HStack>
              <Select
                placeholder="Month"
                name="startMonth"
                value={formValues.startMonth}
                onChange={handleChange}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Year"
                name="startYear"
                value={formValues.startYear}
                onChange={handleChange}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </HStack>
            <FormErrorMessage>{formErrors.startDate}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formTouched && formErrors.endDate} width="50%">
            <Flex justify="space-between" align="center">
              <FormLabel mb={0}>End Date</FormLabel>
              <Box>
                <input
                  type="checkbox"
                  name="present"
                  checked={formValues.present}
                  onChange={handleChange}
                  style={{ marginRight: "0.5rem" }}
                />
                Present
              </Box>
            </Flex>
            {!formValues.present && (
              <HStack mt={2}>
                <Select
                  placeholder="Month"
                  name="endMonth"
                  value={formValues.endMonth}
                  onChange={handleChange}
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </Select>
                <Select
                  placeholder="Year"
                  name="endYear"
                  value={formValues.endYear}
                  onChange={handleChange}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </HStack>
            )}
            {!formValues.present && <FormErrorMessage>{formErrors.endDate}</FormErrorMessage>}
          </FormControl>
        </HStack>
      </VStack>
    </form>
  );
};

/**
 * WorkExperience renders the list of work experience items and allows adding, editing and deleting
 * using a modal form.
 * (No changes made to work experience components.)
 */
const WorkExperience = ({ workExperience, setWorkExperience, setFormValidExperience }) => {
  const [isWorkModalOpen, setIsWorkModalOpen] = useState(false);
  const [editingWork, setEditingWork] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [formTouched, setFormTouched] = useState(false);

  useEffect(() => {
    setFormValidExperience(workExperience && workExperience.length > 0);
  }, [workExperience, setFormValidExperience]);

  const closeWorkModal = () => {
    setIsWorkModalOpen(false);
    setEditingWork(null);
    setFormErrors({});
    setFormTouched(false);
  };

  const openWorkModal = () => setIsWorkModalOpen(true);

  const addOrUpdateWorkExperience = (work) => {
    if (editingWork) {
      setWorkExperience(
        workExperience.map((item) =>
          item.id === editingWork.id ? { ...work, id: editingWork.id } : item
        )
      );
    } else {
      setWorkExperience([...workExperience, { ...work, id: Date.now() }]);
    }
    closeWorkModal();
  };

  const deleteWorkExperience = (id) => {
    setWorkExperience(workExperience.filter((item) => item.id !== id));
  };

  const editWorkExperience = (id) => {
    setEditingWork(workExperience.find((item) => item.id === id));
    openWorkModal();
  };

  return (
    <Box as="section">
      <Text fontSize="md" mb={4} textAlign="center" mx="auto">
        Add your work experience to showcase your skills and expertise
      </Text>
      <Container maxW="3xl">
        <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" p={{ base: "4", md: "6" }}>
          <Stack
            spacing="5"
            divider={<StackDivider />}
            justifyContent={{ base: "center" }}
            alignContent={{ base: "center" }}
          >
            <Button
              leftIcon={<BsPlusLg />}
              variant="ExperienceBankGreen"
              onClick={openWorkModal}
              maxW="250px"
              mx="auto"
            >
              Add Work Experience
            </Button>
            {workExperience &&
              workExperience.length > 0 &&
              workExperience.map((work) => (
                <WorkExperienceItem
                  key={work.id}
                  work={work}
                  onDelete={deleteWorkExperience}
                  onEdit={editWorkExperience}
                  canDelete={workExperience.length > 1}
                />
              ))}
          </Stack>
        </Box>
      </Container>

      <Modal isOpen={isWorkModalOpen} onClose={closeWorkModal} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl" bg="oleniumBlack.700">
          <ModalHeader borderBottomWidth="1px" py={4}>
            {editingWork ? "Edit Work Experience" : "Add Work Experience"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <WorkExperienceForm
              initialValues={editingWork}
              onSubmit={addOrUpdateWorkExperience}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              setFormTouched={setFormTouched}
              formTouched={formTouched}
            />
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={closeWorkModal}>
              Close
            </Button>
            <Spacer />
            <Button type="submit" form="work-experience-form" variant="ExperienceBankGreen">
              {editingWork ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

/**
 * CapabilityCard and EditSelectedServices are used to render the Selected Services UI in edit mode.
 * (These remain unmodified.)
 */
const CapabilityCard = ({
  value,
  title,
  description,
  icon,
  onToggle,
  selectedOptions,
  isDisabled,
  selectedSubTags,
  setSelectedSubTags,
  service,
}) => {
  const textColor = useColorModeValue("oleniumBlack.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.300");
  const borderColor = useColorModeValue("oleniumBlack.200", "oleniumBlack.600");
  const borderColorActive = useColorModeValue("#A0B100", "#A0B100");
  const disabledTextColour = useColorModeValue("gray.100", "white");
  const disabledBoxColour = useColorModeValue("oleniumBlack.300", "oleniumBlack.500");

  const handleClick = () => {
    if (!isDisabled) {
      onToggle(value);
    }
  };

  const handleSubTagClick = (e, tag) => {
    e.stopPropagation();
    setSelectedSubTags(tag);
  };

  return (
    <motion.div transition={{ delay: 0.2 }}>
      <Box
        borderColor={selectedOptions.includes(value) && !isDisabled ? borderColorActive : borderColor}
        shadow={selectedOptions.includes(value) && !isDisabled ? "lg" : ""}
        borderRadius="2xl"
        p={{ base: 3, sm: 4, md: 6 }}
        pb={{ base: 4, sm: 6, md: 6 }}
        m={{ base: 0, md: 4 }}
        my={{ base: 2 }}
        bg={isDisabled ? disabledBoxColour : boxColor}
        borderWidth="2px"
        onClick={handleClick}
        cursor={!isDisabled ? "pointer" : "not-allowed"}
        _hover={{
          borderColor: !isDisabled ? borderColorActive : borderColor,
        }}
      >
        <HStack spacing={{ base: 3, md: 2 }}>
          <Box>
            <Text
              color={isDisabled ? disabledTextColour : textColor}
              fontSize={{ base: "sm", sm: "sm", md: "lg", lg: "lg" }}
              fontWeight="bold"
              ml={3}
              mb={1}
              align="left"
            >
              {title}
            </Text>
            <Text
              color={isDisabled ? disabledTextColour : textColor}
              fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "md" }}
              ml={3}
              align="left"
            >
              {description}
            </Text>
            {selectedOptions.includes(value) && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <Divider m={3} />
                <Text fontSize="sm" color={textColor} ml={3} mt={2} fontWeight="bold" textAlign={"left"}>
                  Choose a sub section of {title}:
                </Text>
                <VStack align="start" spacing={1} ml={3}>
                  <HStack wrap={"wrap"} mt={4}>
                    {service.sub_tags.map((tag, index) => (
                      <Tag
                        key={index}
                        size="md"
                        w="fit-content"
                        bg={selectedSubTags.includes(tag) ? "#A0B100" : "oleniumBlack.500"}
                        color="white"
                        fontWeight="bold"
                        cursor="pointer"
                        onClick={(e) => handleSubTagClick(e, tag)}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </HStack>
                </VStack>
              </motion.div>
            )}
          </Box>
        </HStack>
      </Box>
    </motion.div>
  );
};

const EditSelectedServices = ({ selectedServices, setSelectedServices, availableServices }) => {
  const handleToggle = (serviceId) => {
    const updatedSelected = { ...selectedServices };
    if (updatedSelected[serviceId]) {
      delete updatedSelected[serviceId];
    } else {
      updatedSelected[serviceId] = { subTags: [] };
    }
    setSelectedServices(updatedSelected);
  };

  const handleSubTagToggle = (serviceId, tag) => {
    const updatedSelected = { ...selectedServices };
    if (!updatedSelected[serviceId]) {
      updatedSelected[serviceId] = { subTags: [] };
    }
    const currentSubTags = updatedSelected[serviceId].subTags;
    if (currentSubTags.includes(tag)) {
      updatedSelected[serviceId].subTags = currentSubTags.filter((t) => t !== tag);
    } else {
      updatedSelected[serviceId].subTags = [...currentSubTags, tag];
    }
    setSelectedServices(updatedSelected);
  };

  // Identify any services stored in the application that are missing from the available list.
  const missingServiceIds = Object.keys(selectedServices).filter(
    (id) => !availableServices.some((service) => service.id === id)
  );

  return (
    <Box>
      <Text fontSize="md" color="white" textAlign="center" pb={4} maxW="500px" mx="auto">
        Select the capabilities you offer. You can select multiple capabilities. <b>You can select more than one capability and sub-capability.</b>
      </Text>
      <CheckboxGroup value={Object.keys(selectedServices)}>
        {availableServices.map((service, index) => (
          <motion.div
            key={service.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 * index }}
          >
            <CapabilityCard
              value={service.id}
              title={service.name}
              description={service.description}
              icon={service.icon}
              onToggle={handleToggle}
              selectedOptions={Object.keys(selectedServices)}
              isDisabled={!service.is_active}
              selectedSubTags={selectedServices[service.id]?.subTags || []}
              setSelectedSubTags={(tag) => handleSubTagToggle(service.id, tag)}
              service={service}
            />
          </motion.div>
        ))}
      </CheckboxGroup>
      {missingServiceIds.length > 0 &&
        missingServiceIds.map((id) => (
          <Alert status="warning" mt={4} rounded="xl" key={id}>
            <AlertIcon />
            Service not found: {id}
          </Alert>
        ))}
    </Box>
  );
};

/**
 * ProviderManager renders a list of provider applications and allows the admin to view,
 * edit and approve or reject an application.
 * In view mode, Application Details are shown in card-like sections.
 * In edit mode, the layout is changed to be more compact with improved Personal Info.
 */
const ProviderManager = () => {
  const navigate = useNavigate();
  const onBack = () => {
    navigate("/admin-panel");
  };

  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [lastKey, setLastKey] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  // In editForm, selected_services is stored as an object.
  const [editForm, setEditForm] = useState({
    bio: "",
    status: "",
    selected_services: {},
    experience: [],
  });
  // Store available services from the API.
  const [availableServices, setAvailableServices] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isApproveOpen,
    onOpen: onApproveOpen,
    onClose: onApproveClose,
  } = useDisclosure();
  const {
    isOpen: isRejectOpen,
    onOpen: onRejectOpen,
    onClose: onRejectClose,
  } = useDisclosure();
  const [actionLoading, setActionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceIdToNameMapping, setServiceIdToNameMapping] = useState({});
  const [pendingAction, setPendingAction] = useState(null);

  const fetchApplications = async (append = false) => {
    try {
      setLoading(true);
      const authentication = await getIdToken();
      let url = `${API_URL}/provider/all?page_size=5`;
      if (append && lastKey) {
        url += `&last_key=${encodeURIComponent(lastKey)}`;
      }
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      if (response.data.statusCode === 200) {
        const parsedBody = JSON.parse(response.data.body);
        const newApplications = parsedBody.applications;

        if (Array.isArray(newApplications)) {
          const applicationsWithDetails = await Promise.all(
            newApplications.map(async (app) => {
              const userResponse = await fetch(
                `${API_URL}/users/info/${app.provider_id}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authentication}`,
                  },
                }
              );
              const userData = await userResponse.json();
              if (userData.statusCode === 200) {
                app.given_name = userData.body.given_name;
                app.family_name = userData.body.family_name;
                app.profile_picture_url = userData.body.profile_picture_url;
              }
              return app;
            })
          );
          if (append) {
            setApplications((prev) => [...prev, ...applicationsWithDetails]);
          } else {
            setApplications(applicationsWithDetails);
          }
        }
        if (parsedBody.last_key) {
          setLastKey(parsedBody.last_key);
        } else {
          setLastKey(null);
        }
      } else {
        console.error("Error status code:", response.data.statusCode);
        if (!append) setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
    setLoading(false);
  };

  const fetchServices = async () => {
    try {
      const IdToken = await getIdToken();
      const response = await axios.get(`${API_URL}/services`, {
        headers: {
          Authorization: `Bearer ${IdToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 && response.data.statusCode === 200) {
        const servicesData = JSON.parse(response.data.body);
        if (Array.isArray(servicesData)) {
          setAvailableServices(servicesData);
          const idToName = {};
          servicesData.forEach((service) => {
            idToName[service.id] = service.name;
          });
          setServiceIdToNameMapping(idToName);
        } else {
          throw new Error("Failed to fetch services.");
        }
      } else {
        throw new Error("Failed to fetch services.");
      }
    } catch (error) {
      console.error("Error fetching services", error);
    }
  };

  useEffect(() => {
    fetchApplications();
    fetchServices();
  }, []);

  const viewApplication = (app) => {
    setSelectedApplication(app);
    setIsEditing(false);
    setEditForm({
      bio: app.bio || "",
      status: app.status || "",
      selected_services: app.selected_services || {},
      experience: app.experience || [],
    });
    onOpen();
  };

  const handleApprove = async (userId) => {
    try {
      setActionLoading(true);
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/provider/approve/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        }
      );
      if (response.data.statusCode === 200) {
        setActionLoading(false);
        fetchApplications();
        onApproveClose();
      }
    } catch (error) {
      console.error("Error approving application:", error);
    }
    setActionLoading(false);
  };

  const handleReject = async (userId) => {
    try {
      setActionLoading(true);
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/provider/reject/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        }
      );
      if (response.data.statusCode === 200) {
        setActionLoading(false);
        fetchApplications();
        onRejectClose();
      }
    } catch (error) {
      console.error("Error rejecting application:", error);
    }
    setActionLoading(false);
  };

  const confirmAction = (action, userId) => {
    setPendingAction({ action, userId });
    if (action === "approve") {
      onApproveOpen();
    } else {
      onRejectOpen();
    }
  };

  const convertTimeToDaysOld = (time) => {
    const now = new Date();
    const created = new Date(time);
    const diff = now.getTime() - created.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days === 0) return "Today";
    if (days === 1) return "Yesterday";
    return days + " days ago";
  };

  const renderStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return <Tag colorScheme="green">Approved</Tag>;
      case "REJECTED":
        return <Tag colorScheme="red">Rejected</Tag>;
      case "PENDING":
        return <Tag colorScheme="yellow">Pending</Tag>;
      default:
        return <Tag colorScheme="yellow">Unknown</Tag>;
    }
  };

  const handleEditChange = (field, value) => {
    setEditForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      setActionLoading(true);
      const authentication = await getIdToken();
      const updatePayload = {
        provider_id: selectedApplication.provider_id,
        bio: editForm.bio,
        // Status is not updated via edit state
        selected_services: editForm.selected_services,
        experience: editForm.experience,
      };
      const response = await axios.put(
        `${API_URL}/provider/admin/update`,
        updatePayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        }
      );
      if (response.data.statusCode === 200 || response.status === 200) {
        fetchApplications();
        setSelectedApplication(response.data.updated_item);
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error updating application:", error);
    }
    setActionLoading(false);
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          Mentors
        </Heading>
      </Flex>
      {!loading ? (
        <>
          <Box overflowX="auto">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "left", padding: "8px" }}>User</th>
                  <th style={{ textAlign: "left", padding: "8px" }}>Submitted At</th>
                  <th style={{ textAlign: "left", padding: "8px" }}>Status</th>
                  <th style={{ textAlign: "left", padding: "8px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {applications.map((app) => (
                  <tr key={app.provider_id}>
                    <td style={{ padding: "8px" }}>
                      <HStack spacing={2}>
                        <Avatar
                          size="xs"
                          name={app.given_name + " " + app.family_name}
                          src={app.profile_picture_url}
                        />
                        <Text ml={1} fontSize="sm" fontWeight="bold">
                          {app.given_name + " " + app.family_name}
                        </Text>
                      </HStack>
                    </td>
                    <td style={{ padding: "8px" }}>{convertTimeToDaysOld(app.created_at)}</td>
                    <td style={{ padding: "8px" }}>{renderStatus(app.status)}</td>
                    <td style={{ padding: "8px" }}>
                      <HStack spacing={6}>
                        <ButtonGroup isAttached variant="outline">
                          <Button
                            colorScheme="green"
                            variant="outline"
                            onClick={() => confirmAction("approve", app.provider_id)}
                            isDisabled={app.status === "APPROVED"}
                            isLoading={actionLoading}
                          >
                            Approve
                          </Button>
                          <Button
                            colorScheme="red"
                            variant="outline"
                            onClick={() => confirmAction("reject", app.provider_id)}
                            isDisabled={app.status === "REJECTED"}
                            isLoading={actionLoading}
                          >
                            Reject
                          </Button>
                        </ButtonGroup>
                        <Button variant="ExperienceBankGreen" onClick={() => viewApplication(app)}>
                          View
                        </Button>
                      </HStack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          {lastKey && (
            <Center mt={5}>
              <Button onClick={() => fetchApplications(true)} isLoading={loading}>
                Load More
              </Button>
            </Center>
          )}
        </>
      ) : (
        <Center>
          <Spinner size="xl" />
        </Center>
      )}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsEditing(false);
          onClose();
        }}
        size="6xl"
        scrollBehaviour="inside"
      >
        <ModalOverlay />
        <ModalContent background="oleniumBlack.600" color="white">
          <ModalHeader>Application Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedApplication &&
              (!isEditing ? (
                // View state (unchanged)
                <VStack spacing={4} align="stretch">
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <Heading size="md" mb={2}>
                      Personal Info
                    </Heading>
                    <Text>
                      <strong>Name:</strong> {selectedApplication.given_name} {selectedApplication.family_name}
                    </Text>
                    <Text>
                      <strong>Submitted:</strong>{" "}
                      {new Date(selectedApplication.created_at).toLocaleString()}
                    </Text>
                    <Text>
                      <strong>Identity Verified:</strong>{" "}
                      {selectedApplication.identity_verified ? "Yes" : "No"}
                    </Text>
                  </Box>
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <Heading size="md" mb={2}>
                      Bio
                    </Heading>
                    <Text>{selectedApplication.bio}</Text>
                  </Box>
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <Heading size="md" mb={2}>
                      Selected Services
                    </Heading>
                    <Wrap spacing={4} justify="flex-start">
                      {Object.entries(selectedApplication.selected_services || {}).map(
                        ([serviceId, serviceDetails]) => (
                          <WrapItem key={serviceId}>
                            <Box borderWidth="1px" borderRadius="md" p={3} backgroundColor="gray.800">
                              <Tag colorScheme="green" variant="solid" mb={2}>
                                {serviceIdToNameMapping[serviceId] || serviceId}
                              </Tag>
                              <Wrap spacing={2} mt={2}>
                                {serviceDetails.subTags.map((subTag, index) => (
                                  <WrapItem key={index}>
                                    <Tag colorScheme="blue" variant="outline">
                                      {subTag}
                                    </Tag>
                                  </WrapItem>
                                ))}
                              </Wrap>
                            </Box>
                          </WrapItem>
                        )
                      )}
                    </Wrap>
                  </Box>
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <Heading size="md" mb={2}>
                      Work Experience
                    </Heading>
                    <Accordion allowMultiple width="100%">
                      {selectedApplication.experience.map((exp, index) => (
                        <AccordionItem key={index}>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                {exp.title} at {exp.company}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Text>
                              <strong>Location:</strong> {exp.location}, {exp.country}
                            </Text>
                            <Text>
                              <strong>Duration:</strong> {exp.startDate} - {exp.endDate || "Present"}
                            </Text>
                            <Text>
                              <strong>Description:</strong> {exp.description}
                            </Text>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </Box>
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <Heading size="md" mb={2}>
                      Status
                    </Heading>
                    {renderStatus(selectedApplication.status)}
                  </Box>
                </VStack>
              ) : (
                // Edit state: a compact vertical layout with improved Personal Info section.
                <VStack spacing={4} align="stretch">
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <HStack spacing={6} align="center" justify="space-between">
                      <Box>
                        <Heading size="md" mb={1}>
                          Personal Info
                        </Heading>
                        <Text>
                          <strong>Name:</strong> {selectedApplication.given_name} {selectedApplication.family_name}
                        </Text>
                        <Text>
                          <strong>Submitted:</strong> {new Date(selectedApplication.created_at).toLocaleString()}
                        </Text>
                        <Text>
                          <strong>Verified:</strong> {selectedApplication.identity_verified ? "Yes" : "No"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <Heading size="md" mb={2}>
                      Bio
                    </Heading>
                    <Textarea
                      value={editForm.bio}
                      onChange={(e) => handleEditChange("bio", e.target.value)}
                      placeholder="Enter bio"
                    />
                  </Box>
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <Heading size="md" mb={2}>
                      Selected Services
                    </Heading>
                    <EditSelectedServices
                      selectedServices={editForm.selected_services}
                      setSelectedServices={(newServices) => handleEditChange("selected_services", newServices)}
                      availableServices={availableServices}
                    />
                  </Box>
                  <Box bg="gray.700" p={4} borderRadius="md">
                    <Heading size="md" mb={2}>
                      Work Experience
                    </Heading>
                    <WorkExperience
                      workExperience={editForm.experience}
                      setWorkExperience={(newExp) => handleEditChange("experience", newExp)}
                      setFormValidExperience={() => {}}
                    />
                  </Box>
                </VStack>
              ))}
          </ModalBody>
          <ModalFooter>
            {isEditing ? (
              <>
                <Button variant="ExperienceBankGreen" onClick={handleSaveChanges} isLoading={actionLoading}>
                  Save Changes
                </Button>
                <Button ml={3} onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button variant="ExperienceBankGreen" onClick={() => setIsEditing(true)}>
                  Edit
                </Button>
                <Button ml={3} onClick={onClose}>
                  Close
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isApproveOpen} onClose={onApproveClose} isCentered>
        <ModalOverlay />
        <ModalContent background="oleniumBlack.600" color="white">
          <ModalHeader>Confirm Approval</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to approve this application? An email will be sent to the mentor confirming this decision.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onApproveClose}>
              Cancel
            </Button>
            <Button onClick={() => handleApprove(pendingAction.userId)} variant="ExperienceBankGreen">
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isRejectOpen} onClose={onRejectClose}>
        <ModalOverlay />
        <ModalContent background="oleniumBlack.600" color="white">
          <ModalHeader>Confirm Rejection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to reject this application? An email will be sent to the mentor confirming this decision.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onRejectClose}>
              Cancel
            </Button>
            <Button onClick={() => handleReject(pendingAction.userId)} variant="ExperienceBankGreen">
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProviderManager;
