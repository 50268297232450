import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Select,
  Spacer,
  VStack,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormLabel,
  InputLeftElement
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const API_URL = process.env.REACT_APP_API_URL;

const EnterpriseAccountsTable = () => {
  const [accounts, setAccounts] = useState([]); // Initialize as empty array
  const [currentAccount, setCurrentAccount] = useState(null);
  const [action, setAction] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = async () => {
    try {
      const authentication = await getIdToken();
      const response = await axios.get(`${API_URL}/enterprise`, {
        headers: {
          Authorization: `Bearer ${authentication}`,
        },
      });
      setAccounts(response.data || []); // Ensure accounts is always an array
    } catch (error) {
      console.error("Error fetching corporate accounts:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = (account, modalAction) => {
    setAction(modalAction);
    setCurrentAccount(account);
    onOpen();
  };

  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <HStack>
          <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
          <Heading as="h1" size="lg" ml={5} mt={1}>
            Corporate Accounts
          </Heading>
        </HStack>
        <Button onClick={() => openModal(null, "Create")} >
          Create New Account
        </Button>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Total Accounts
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {accounts.length}
            </Text>
          </Box>
        </GridItem>
      </Grid>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Account Name</Th>
            <Th>Website</Th>
            <Th>Credits Issued</Th>
            <Th>Credits Remaining</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {accounts.map((account) => (
            <Tr key={account.account_id}>
              <Td>{account.name}</Td>
              <Td>{account.website}</Td>
              <Td>
                <Tag size="md" colorScheme="green">
                  {account.credits_issued}
                </Tag>
              </Td>
              <Td>
                <Tag size="md" colorScheme="blue">
                  {account.credits_remaining}
                </Tag>
              </Td>

              <Td>
                <Menu bg={"oleniumBlack.700"}>
                  <MenuButton>
                    <IconButton
                      aria-label="Options"
                      icon={<BsThreeDotsVertical />}
                      size="xs"
                      variant="outline"
                      rounded="full"
                      mx={2}
                    />
                  </MenuButton>
                  <MenuList bg={"oleniumBlack.700"}>
                    <MenuItem
                      onClick={() => openModal(account, "View")}
                      bg={"oleniumBlack.700"}
                    >
                      View Account
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => openModal(account, "Edit")}
                      bg={"oleniumBlack.700"}
                    >
                      Edit Account
                    </MenuItem> */}
                    {/* <MenuItem
                      onClick={() => openModal(account, "Delete")}
                      bg={"oleniumBlack.700"}
                      color={"red.600"}
                    >
                      Delete Account
                    </MenuItem> */}
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        currentAccount={currentAccount}
        action={action}
        fetchData={fetchData}
      />
    </Box>
  );
};

const ActionModal = ({ isOpen, onClose, currentAccount, action, fetchData }) => {
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [creditsIssued, setCreditsIssued] = useState(0);
  const [contractValue, setContractValue] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [valuePerHour, setValuePerHour] = useState(0);
  const [isWarning, setIsWarning] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (currentAccount && action !== "Create") {
      setName(currentAccount.name);
      setWebsite(currentAccount.website);
      setCreditsIssued(currentAccount.credits_issued);
      setContractValue(currentAccount.contract_value || 0);
    } else {
      setName("");
      setWebsite("");
      setCreditsIssued(0);
      setContractValue(0);
    }
  }, [currentAccount, action]);

  useEffect(() => {
    setIsValid(name !== "" && website !== "" && creditsIssued > 0 && contractValue > 0);
    const calculatedValuePerHour = contractValue / creditsIssued;
    setValuePerHour(calculatedValuePerHour);
    setIsWarning(calculatedValuePerHour < 150);
  }, [name, website, creditsIssued, contractValue]);

  const handleConfirm = async () => {
    try {
      const authentication = await getIdToken();
      const payload = {
        name,
        website,
        credits_issued: creditsIssued,
        contract_value: contractValue,
      };

      if (action === "Create") {
        await axios.post(`${API_URL}/enterprise`, payload, {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        });
        toast({
          title: "Account Created.",
          description: "The enterprise account has been successfully created.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else if (action === "Edit") {
        await axios.put(`${API_URL}/enterprise/${currentAccount.account_id}`, payload, {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        });
        toast({
          title: "Account Updated.",
          description: "The enterprise account has been successfully updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else if (action === "Delete") {
        await axios.delete(`${API_URL}/enterprise/${currentAccount.account_id}`, {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        });
        toast({
          title: "Account Deleted.",
          description: "The enterprise account has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      fetchData();
      onClose();
    } catch (error) {
      console.error(`Error performing ${action} action on account:`, error);
      toast({
        title: "An error occurred.",
        description: `Could not ${action.toLowerCase()} the account.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const calculateProfit = () => {
    const profitPerHour = 50; // £150 charged per hour - £100 cost per hour
    return creditsIssued * profitPerHour;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered scrollBehavior="inside" borderRadius="xl">
      <ModalOverlay />
      <ModalContent bg={"oleniumBlack.700"} p={6}>
        <ModalHeader>{action} Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {action !== "Delete" ? (
            <VStack align="start" mx={"auto"} w="100%">
              <FormLabel mb={0} mt={2}>
                Account Holder Name
              </FormLabel>
              <Text fontSize="sm" color="gray.400">
                Enter the name of the account holder. For example Nissan.
              </Text>
              <Input
                placeholder="Account Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                isDisabled={action === "View"}
              />
              <FormLabel mb={0} mt={2}>
                Website
              </FormLabel>
              <Text fontSize="sm" color="gray.400">
                Enter the website of the account holder. For example www.nissan.com.
              </Text>
              <InputGroup>
                <InputLeftAddon children="https://" />
                <Input type="url" placeholder="Website" value={website} onChange={(e) => setWebsite(e.target.value)} isDisabled={action === "View"} />
              </InputGroup>

              <FormLabel mb={0} mt={2}>
                Hours Credited
              </FormLabel>
              <Text fontSize="sm" color="gray.400">
                Enter the amount of hours credited to the account.
              </Text>
              <NumberInput step={1} defaultValue={100} min={1} max={1000} onChange={(value) => setCreditsIssued(value)} value={creditsIssued} isDisabled={action === "View"}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <FormLabel mb={0} mt={2}>
                Contract Value (£)
              </FormLabel>
              <Text fontSize="sm" color="gray.400">
                Enter the total contract value.
              </Text>
              <InputGroup>
                <NumberInput step={1} min={0} onChange={(value) => setContractValue(value)} value={contractValue} isDisabled={action === "View"}>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="£"
                  />
                  <NumberInputField pl={8} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>

                </NumberInput>
              </InputGroup>

              {contractValue > 0 && creditsIssued > 0 && (
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={4}>
                  <GridItem colSpan={1}>
                    <FormLabel mb={0} mt={2}>
                      Value Per Hour (£)
                    </FormLabel>
                    <Text fontSize="3xl" color={isWarning ? "red.500" : "green.500"} fontWeight="bold">
                      £{valuePerHour.toFixed(2)}
                    </Text>
                  </GridItem>
                  {/* <GridItem colSpan={1}>
                    <FormLabel mb={0} mt={2}>
                      Profit after payout (£)
                    </FormLabel>
                    <Text fontSize="3xl" color="white" fontWeight="bold">
                      £{calculateProfit().toFixed(2)}
                    </Text>
                  </GridItem> */}
                </Grid>
              )}

              {isWarning && (
                <Text fontSize="sm" color="red.500">
                  Warning: The value per hour is below £150.
                </Text>
              )}
            </VStack>
          ) : (
            <Text mb={4}>
              Are you sure you want to delete this account? This action cannot be undone.
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost">
            Close
          </Button>
          <Spacer />
          <Button
            variant="solid"
            onClick={handleConfirm}
            isDisabled={!isValid || isWarning || action === "View"}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EnterpriseAccountsTable;
